<template>
  <div>
    <template v-if="edit_widget">
      <Button
          class="p-button-transparent context-menu"
          type="button"
          icon="mdi mdi-dots-vertical"
          @click="menu_toggle"
      />
    </template>
    <Menu ref="menu" :model="menu_items" :popup="true"/>
    <Dialog :header="widget_dialog.title" v-model:visible="widget_dialog_visible" :modal="true"
            :breakpoints="{'1800px': '55vw','1440px': '80vw', '960px': '90vw' }"
            :style="{width: '45vw'}" @hide="context_menu_hide">
      <form @submit.prevent="">
        <TabView ref="tabview">
          <TabPanel>
            <template #header>
              <span>{{ t('message.dashboard.general') }}</span>
            </template>
            <!-- Widget Title Text -->
            <div class="p-d-flex p-flex-column gap-4">
              <div class="p-grid p-ai-center">
                <div class="p-col-4 mke-p-0">Titel</div>
                <div class="p-col-8 mke-p-0">
                  <InputText class="block" type="text" v-model="widget_dialog.title"/>
                </div>
              </div>
              <!-- Widget Type Selector-->
              <div class="p-grid p-ai-center">
                <div class="p-col-4 mke-p-0">{{ t('message.dashboard.widgetArt') }}</div>
                <div class="p-col-8 mke-p-0">
                  <Dropdown class="block" type="text" v-model="widget_dialog.type" optionLabel="name" optionValue="code"
                            :options="options_widget_type"  @change="widget_type_changed"/>
                </div>
              </div>
              <!-- Widget Template Selector-->
              <div class="p-grid p-ai-center">
                <div class="p-col-4 mke-p-0">{{ t('message.dashboard.widgetTemplate') }}</div>
                <div class="p-col-8 mke-p-0">
                  <Dropdown class="block" type="text" v-model="widget_dialog_template_selector" optionLabel="name" optionValue="widget_json"
                            :options="options_widget_template"  @change="widget_template_changed" v-bind:placeholder="t('message.dashboard.select_widget_template')" :filter="true" v-bind:filterPlaceholder="t('message.dashboard.select_widget_template')"/>
                </div>
              </div>
            </div>

          </TabPanel>
          <TabPanel >
            <template #header>
              <span :class="{'p-error':((widget_dialog_validator.cube !== undefined && widget_dialog_validator.cube.$invalid) || (widget_dialog_validator.selected_single_kpi !== undefined && widget_dialog_validator.selected_single_kpi.$invalid) || (widget_dialog_validator.groupedKpis !== undefined && widget_dialog_validator.groupedKpis.$invalid) || (widget_dialog_validator.xaxis !== undefined && widget_dialog_validator.xaxis.$invalid) || (widget_dialog_validator.group !== undefined && widget_dialog_validator.group.$invalid) || (widget_dialog_validator.limit !== undefined && widget_dialog_validator.limit.$invalid)) && widget_dialog_submitted}">{{ t('message.dashboard.data') }}</span>
            </template>
            <div class="p-d-flex p-flex-column gap-4">
              <!-- Cube Selector-->
              <div class="p-grid p-ai-center">
                <div class="p-col-4 mke-p-0" :class="{'p-error':widget_dialog_validator.cube.$invalid && widget_dialog_submitted}">{{ t('message.dashboard.cube') }}</div>
                <div class="p-col-8 mke-p-0">
                  <Dropdown class="block" v-model="widget_dialog.cube" :options="options_cubes_dialog" optionLabel="name"
                            optionValue="code"
                            :class="{'p-invalid':widget_dialog_validator.cube.$invalid && widget_dialog_submitted}"
                            @change="cube_changed"/>
                </div>
              </div>
              <!-- KPI Selector-->
              <template v-if="widget_dialog_validator.selected_single_kpi">
                <div class="p-grid p-ai-center">
                  <div class="p-col-4 mke-p-0" :class="{'p-error':widget_dialog_validator.selected_single_kpi.$invalid && widget_dialog_submitted}">KPI</div>
                  <div class="p-col-8 mke-p-0">
                    <CascadeSelect class="block" v-model="widget_dialog.selected_single_kpi" :options="options_single_kpi" optionLabel="cname"
                                   optionGroupLabel="name" @change="kpis_changed"
                                   :class="{'p-invalid':widget_dialog_validator.selected_single_kpi.$invalid && widget_dialog_submitted}"
                                   :optionGroupChildren="['calc']" style="minWidth: 14rem"
                                   placeholder="message.dashboard.selectDatabase">
                      <template #option="slotProps">
                        <div>
                          <i class="pi pi-chart-line p-mr-2" v-if="slotProps.option.kpi"></i>
                          <i class="pi pi-chart-bar p-mr-2" v-if="slotProps.option.calc"></i>
                          <span
                              v-tooltip.right="slotProps.option.descr"><span>{{
                              slotProps.option.cname
                            }}</span> {{ slotProps.option.name }}</span>
                        </div>
                      </template>
                      <template #value="slotProps">
                        <span  v-if="!slotProps.value" >{{ t(slotProps.placeholder) }}</span>
                        <div v-for="(option, index) of slotProps.value" :key="option.cname">
                          <div v-if="index === 'dcode'">
                            {{ option }}
                          </div>
                        </div>
                      </template>
                    </CascadeSelect>
                  </div>
                </div>
              </template>
              <!-- KPI Selector Table-->
              <template v-if="widget_dialog_validator.groupedKpis">
                <div class="p-grid p-ai-center">
                  <div class="p-col-4 mke-p-0"
                       :class="{'p-error':widget_dialog_validator.groupedKpis.$invalid && widget_dialog_submitted}">KPIs
                  </div>
                  <div class="p-col-8 mke-p-0">
                    <MultiSelect class="block" style="width: 100%" v-model="widget_dialog.groupedKpis"
                                 :options="options_multi_kpi"
                                 optionLabel="name" optionGroupLabel="name"
                                 optionGroupChildren="items"
                                 :class="{'p-invalid':widget_dialog_validator.groupedKpis.$invalid && widget_dialog_submitted}"
                                 :filter="true" :showClear="false" @change="kpis_changed">
                    <template #emptyfilter>
                      <span>{{ t('message.dashboard.noValueFound') }}</span>
                    </template>
                    </MultiSelect>
                  </div>
                </div>
              </template>
              <!-- XAXIS Selector-->
              <template v-if="widget_dialog_validator.xaxis">
                <div class="p-grid p-ai-center">
                  <div class="p-col-4 mke-p-0" :class="{'p-error':widget_dialog_validator.xaxis.$invalid && widget_dialog_submitted}">{{ t('message.dashboard.xaxisValue') }}
                  </div>
                  <div class="p-col-8 mke-p-0">
                    <Dropdown class="block" v-model="widget_dialog.xaxis" :options="options_xaxis" optionLabel="kname"
                              optionValue="code"  @change="xaxis_changed"
                              :filter="true" :showClear="false"
                              :class="{'p-invalid':widget_dialog_validator.xaxis.$invalid && widget_dialog_submitted}"
                              >
                      <template #emptyfilter>
                        <span>{{ t('message.dashboard.noxaxisFound') }}</span>
                      </template>
                      </Dropdown>
                  </div>
                </div>
              </template>
              <!-- Group Selector-->
              <template v-if="widget_dialog_validator.group">
                <div class="p-grid p-ai-center">
                  <div class="p-col-4 mke-p-0" :class="{'p-error':widget_dialog_validator.group.$invalid && widget_dialog_submitted}">
                    {{ t('message.dashboard.grouping') }}
                  </div>
                  <div class="p-col-8 mke-p-0" v-if="!widget_dialog_validator.selected_single_kpi">
                    <MultiSelect class="block" v-model="widget_dialog.group" :options="options_group" optionLabel="kname"
                              optionValue="code" style="width: 100%"
                              :class="{'p-invalid':widget_dialog_validator.group.$invalid && widget_dialog_submitted}"
                              :filter="true" :showClear="false" emptyFilterMessage="Keine Gruppierung gefunden"/>
                  </div>
                  <div class="p-col-8 mke-p-0" v-else>
                    <Dropdown class="block" v-model="widget_dialog.group" :options="options_group" optionLabel="kname"
                              optionValue="code" style="width: 100%"
                              :class="{'p-invalid':widget_dialog_validator.group.$invalid && widget_dialog_submitted}"
                              :filter="true" :showClear="false">
                      <template #emptyfilter>
                        <span>{{ t('message.dashboard.noGroupingFound') }}</span>
                      </template>
                    </Dropdown>
                  </div>
                </div>
              </template>
              <!-- Limit Selector-->
              <template v-if="widget_dialog_validator.limit">
                <div class="p-grid p-ai-center">
                  <div class="p-col-4 mke-p-0" :class="{'p-error':widget_dialog_validator.limit.$invalid && widget_dialog_submitted}">
                    {{ t('message.dashboard.number') }}
                  </div>
                  <div class="p-col-8 mke-p-0">
                    <InputNumber id="minmax-buttons" v-model="widget_dialog.limit" mode="decimal" showButtons :min="0"
                                 :max="maxNumber"
                                 :class="{'p-invalid':widget_dialog_validator.limit.$invalid && widget_dialog_submitted}"/>
                  </div>
                </div>
              </template>
              <!-- Sort Selector-->
              <template v-if="widget_dialog_validator.selectedSort">
                <div class="p-grid p-ai-center">
                  <div class="p-col-4 mke-p-0"
                       :class="{'p-error':widget_dialog_validator.selectedSort.$invalid && widget_dialog_submitted}">
                    {{ t('message.dashboard.sorting') }}
                  </div>
                  <div class="p-col-8 mke-p-0">
                    <CascadeSelect class="block" v-model="widget_dialog.selectedSort" :options="options_table_sort" optionLabel="sname"
                                   optionGroupLabel="name" @change="sort_changed"
                                   :class="{'p-invalid':widget_dialog_validator.selectedSort.$invalid && widget_dialog_submitted}"
                                   :optionGroupChildren="['sortOptions']" style="minWidth: 14rem"
                                   placeholder="message.dashboard.selectSortingValue">
                      <template #value="slotProps">
                        <span v-if="!slotProps.value">{{ t(slotProps.placeholder) }}</span>
                        <div v-for="(option, index) of slotProps.value" :key="option.sname">
                          <div v-if="index === 'dcode'">
                            {{ option }}
                          </div>
                        </div>
                      </template>
                    </CascadeSelect>
                  </div>
                </div>
              </template>
              <!-- Query Builder -->
              <Divider layout="horizontal"/>
              <div class="p-grid p-ai-center">
                <div class="p-col-12 mke-p-0 mke-mb-4">{{ t('message.dashboard.filterData') }}</div>
                <div class="p-col-12 mke-p-0">
                  <mke-query-builder ref="mkeQueryBuilder" :cube_name="widget_dialog.cube" @changeRules="changeRules"
                                     :importRules=widget_dialog.widget_filters />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <template #header>
              <span :class="{'p-error':(widget_dialog_template_validator.name.$invalid || widget_dialog_template_validator.description.$invalid) && widget_dialog_template_submitted}">{{ t('message.dashboard.widget_templates') }}</span>
            </template>
            <!-- Widget Template Name -->
            <div class="p-d-flex p-flex-column gap-4">
              <div class="p-grid p-ai-center">
                <div class="p-col-4 mke-p-0" :class="{'p-error':widget_dialog_template_validator.name.$invalid && widget_dialog_template_submitted}">{{t('message.dashboard.template_name')}}</div>
                <div class="p-col-8 mke-p-0">
                  <InputText class="block" :class="{'p-error':widget_dialog_template_validator.name.$invalid && widget_dialog_template_submitted}" type="text" v-model="widget_dialog_template.name"/>
                </div>
              </div>
              <div class="p-grid p-ai-center">
                <div class="p-col-4 mke-p-0" :class="{'p-error':widget_dialog_template_validator.description.$invalid && widget_dialog_template_submitted}">{{t('message.dashboard.template_description')}}</div>
                <div class="p-col-8 mke-p-0">
                  <Textarea rows="8" cols="500" class="block" :class="{'p-error':widget_dialog_template_validator.description.$invalid && widget_dialog_template_submitted}" type="text" v-model="widget_dialog_template.description"/>
                </div>
              </div>
              <div class="p-grid p-ai-center">
                <div class="p-col-4 mke-p-0"></div>
                <Button type="submit" @click="widget_dialog_submit(!widget_dialog_validator.$invalid, !widget_dialog_template_validator.$invalid, 'savetemplate')" :label="t('message.dashboard.save_template')"  class="p-mt-2"/>
              </div>
            </div>


          </TabPanel>
        </TabView>
        <!-- Footer -->
        <Divider layout="horizontal"/>
        <div class="p-d-flex p-jc-end mke-mt-4">
          <Button type="submit" @click="widget_dialog_submit(!widget_dialog_validator.$invalid, !widget_dialog_template_validator.$invalid, 'save')" :label="t('message.dashboard.save')" class="p-mt-2">
          </Button>
        </div>
      </form>
    </Dialog>
  </div>
</template>

<script>

import {computed, ref} from "vue";
import {useStore} from "vuex";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import deepClone from "../../../../utilities";
import mkeQueryBuilder from "./mkeQueryBuilder";
import moment from 'moment';
import { useI18n } from 'vue-i18n'
moment().format();

export default {
  name: 'mkeContextMenu',
  props: ["widget_id"],
  components: {mkeQueryBuilder},
  setup(props) {
    const store = useStore();

//INIT Vars
    //Widget Configuration Object
    const widget = computed(() => store.getters["dashboard/get_widget"](props.widget_id))
    const cubes_store = computed(() => store.getters["dashboard/get_cubenames"]())
    const edit_widget = computed(() => store.getters["dashboard/edit_widget"]());

    //translations
    const { t } = useI18n({ useScope: 'global' })
    // Widget Dialog Object
    const widget_dialog = ref({})
    const widget_dialog_template = ref({})
    const widget_dialog_template_selector = ref({})
    const widget_dialog_visible = ref(false);


    //Query Builder Component
    const mkeQueryBuilder = ref()
    const query_builder_value = ref()

    // Widget Dialog Vue Validate Rules
    const widget_dialog_rules = computed(() => {
      const types = {
        "GraphArea": {cube:{required}, selected_single_kpi: {required}, xaxis: {required}, group: {required}, limit: {required}},
        "GraphBar": {cube:{required}, selected_single_kpi: {required}, xaxis: {required}, group: {required}, limit: {required}, selectedSort: {required}},
        "GraphPi": {cube:{required}, selected_single_kpi: {required}, group: {required}, limit: {required}, selectedSort: {required}},
        "WidgetTable": {cube:{required}, groupedKpis: {required}, group: {required}, selectedSort: {required}, limit: {required}},
        "WidgetSimple": {cube:{required}, selected_single_kpi: {required}}
      }
      return types[widget_dialog.value.type]
    })
    const widget_dialog_validator = useVuelidate(widget_dialog_rules, widget_dialog);
    const widget_dialog_template_rules = {name:{required}, description: {required}}
    const widget_dialog_template_validator = useVuelidate(widget_dialog_template_rules, widget_dialog_template);

//Submit and Validation handling
    const widget_dialog_submitted = ref(false);
    const widget_dialog_template_submitted = ref(false);
    const widget_dialog_submit = (isFormValid, isTemplateValid, type) => {
      if (type === 'save') {
        widget_dialog_submitted.value = true;
        widget_dialog_template_submitted.value = false;
      } else {
        widget_dialog_submitted.value = true;
        widget_dialog_template_submitted.value = true;
      }
      if (isFormValid && type === 'save') {
        widget_dialog_save(widget_dialog, props.widget_id, type)
      } else if (isFormValid && isTemplateValid && type !== 'save') {
        widget_dialog_save(widget_dialog, props.widget_id, type)
      }
    }

//Option Vars
    const options_widget_type = ref([
      {name: t("message.dashboard.simple"), code: 'WidgetSimple'},
      {name: t("message.dashboard.lineChart"), code: 'GraphArea'},
      {name: t("message.dashboard.table"), code: 'WidgetTable'},
      {name: t("message.dashboard.pieChart"), code: 'GraphPi'},
      {name: t("message.dashboard.barChart"), code: 'GraphBar'},
    ]);
    const options_widget_template = ref(store.getters["dashboard/get_widget_templates"]())
    const options_xaxis = ref()
    const options_group = ref()
    const options_single_kpi = ref()
    const options_multi_kpi = ref()
    const options_table_sort = ref([])
    const options_cubes_dialog = ref([])
    const maxNumber = ref(200)


    const mutate_options = () => {
      //Nicht Cube Abhängige Options füllen
      mutate_cubes_dialog_options()
      //Cube Abhängige Options füllen
      //Auswahl eines Single KPI options_single_kpi -> widget_dialog.selected_single_kpi (Char)
      mutate_single_kpi_options()
      //Auswahl eines Multi KPI options_multi_kpi -> widget_dialog.groupedKpis (Array)
      mutate_multi_kpi_options()
      //Auswahl der XAxis options_xaxis -> widget_dialog.xaxis (Char)
      mutate_xaxis_options()
      //Auswahl der Gruppierung options_group -> widget_dialog.group (Char)
      mutate_group_options()
      //Auswahl Table Sort options_table_sort -> widget_dialog.selectedSort (Object)
      mutate_table_sort_options()
    }
    const computed_values = () => {
      if (widget_dialog.value.kpi) {
        computed_single_kpi()
      }
      if (widget_dialog.value.columns.length > 0 ) {
        computed_groupedKpis()
      }
      computed_selectedSort()
    }
//if (widget_dialog.value.type !== "WidgetTable")
    const mutate_cubes_dialog_options = () => {
      options_cubes_dialog.value = cubes_store.value.map(a => {
        return {...a}
      })
    }

    const mutate_xaxis_options = () => {
      if (widget_dialog.value.cube) {
        options_xaxis.value = []
        options_xaxis.value = deepClone(cubes_store.value.filter(cube => cube.code === widget_dialog.value.cube)[0].nokpi)
        options_xaxis.value.sort(function (a, b) {
          var textA = a.kname.toUpperCase();
          var textB = b.kname.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
      }
    }

    const mutate_group_options = () => {
      if (widget_dialog.value.cube) {
        options_group.value = []
        options_group.value = deepClone(cubes_store.value.filter(cube => cube.code === widget_dialog.value.cube)[0].nokpi)
        if (widget_dialog.value.xaxis) {
          options_group.value = options_group.value.filter(function (option) {
            return option.code !== widget_dialog.value.xaxis
          })
        }
      }
    }

    const mutate_single_kpi_options = () => {
      if (widget_dialog.value.cube) {
        options_single_kpi.value = []
        options_single_kpi.value = deepClone(cubes_store.value.filter(cube => cube.code === widget_dialog.value.cube)[0].kpi)
      }
    }

    const mutate_multi_kpi_options = () => {
      if (widget_dialog.value.cube && widget_dialog.value.type == "WidgetTable") {
        options_multi_kpi.value = []
        options_multi_kpi.value = deepClone(cubes_store.value.filter(cube => cube.code === widget_dialog.value.cube)[0].calc)
      }
    }

    const mutate_table_sort_options = () => {
      if (widget_dialog.value.selected_single_kpi) {
        options_table_sort.value = []
         options_table_sort.value.push({
            name: widget_dialog.value.selected_single_kpi.dcode,
            code: widget_dialog.value.selected_single_kpi.code,
            sortOptions: [
              {sname: t("message.dashboard.biggestFirst"), dcode: `${widget_dialog.value.selected_single_kpi.dcode}/${t("message.dashboard.biggestFirst")}`, code: `${widget_dialog.value.selected_single_kpi.code}/descending`},
              {sname: t("message.dashboard.smallestFirst") , dcode: `${widget_dialog.value.selected_single_kpi.dcode}/${t("message.dashboard.smallestFirst")}`, code: `${widget_dialog.value.selected_single_kpi.code}/ascending`}
            ]
          })
      }
      if (widget_dialog.value.groupedKpis) {
        options_table_sort.value = []
        JSON.parse(JSON.stringify(widget_dialog.value.groupedKpis)).forEach((value) => {
          options_table_sort.value.push({
            name: value.name,
            code: value.value,
            sortOptions: [
              {sname: t("message.dashboard.biggestFirst"), dcode: `${value.name}/${t("message.dashboard.biggestFirst")}`, code: `${value.value}/descending`},
              {sname: t("message.dashboard.smallestFirst") , dcode: `${value.name}/${t("message.dashboard.smallestFirst")}`, code: `${value.value}/ascending`}
            ]
          })
        })
      }

    }
    const computed_single_kpi = () => {
      try {
        let thisselectedkpi = cubes_store.value.filter(cube => cube.code === widget_dialog.value.cube)[0].kpi.filter(kpi => kpi.code === widget_dialog.value.kpi)[0].calc.filter(calc => calc.code === `${widget_dialog.value.cube}/${widget_dialog.value.kpi}/${widget_dialog.value.calc}`)[0]
        widget_dialog.value.selected_single_kpi = {
          "cname": thisselectedkpi.cname,
          "dcode": thisselectedkpi.dcode,
          "code": thisselectedkpi.code
        }
      } catch (err) {
        console.log(err)
      }
    }
    const computed_groupedKpis = () =>{
      let newKpis = []
      mutate_multi_kpi_options()
      widget_dialog.value.groupedKpis = options_multi_kpi.value.filter((element) => widget_dialog.value.columns.some((column) => column.agg_type === element.code))
          .map(element => {
            let newElt = Object.assign({}, element); // copies element
            let kpis = newElt.items.filter((element) =>
                widget_dialog.value.columns.some((column) => `${widget_dialog.value.cube}/${column.agg_type}(${column.field})` === element.value))
            newKpis = newKpis.concat(kpis)
            return newKpis;
          }).slice(-1)[0]
    }
    const computed_selectedSort = () => {
        let sort = {}
        if (widget_dialog.value.groupedKpis) {
          let sort_list = widget_dialog.value.groupedKpis.filter(el => el.value.split('/')[1] === widget_dialog.value.sort[0])
          sort.code = sort_list[0]['value']
          sort.dcode = sort_list[0]['name']
        } else if (widget_dialog.value.selected_single_kpi) {
          sort.code = widget_dialog.value.selected_single_kpi.code
          sort.dcode = widget_dialog.value.selected_single_kpi.dcode
        }
        if (widget_dialog.value.sort_ascending) {
          widget_dialog.value.selectedSort = {
            sname: t("message.dashboard.smallestFirst"),
            dcode: `${sort.dcode}/${t("message.dashboard.smallestFirst")}`,
            code: `${sort.code}/ascending`
          }
        } else {
          widget_dialog.value.selectedSort = {
            sname: t("message.dashboard.biggestFirst"),
            dcode: `${sort.dcode}/${t("message.dashboard.biggestFirst")}`,
            code: `${sort.code}/descending`
          }
        }
    }
//groupedKpis,selectedSort,selected_single_kpi werden nicht in widget gespeichert
    const remove_properties = () => {
      delete widget_dialog.value.groupedKpis
      delete widget_dialog.value.selectedSort
      delete widget_dialog.value.selected_single_kpi
    }
// set max limit for WidgetTable and GraphBar
  const set_maxLimit = () => {
    if (widget_dialog.value.type === "WidgetTable") {
      maxNumber.value = 10000
    } else if (widget_dialog.value.type === "GraphBar"){
      maxNumber.value = 10000
    } else if (widget_dialog.value.type === "GraphArea"){
      maxNumber.value = 10000
    } else if (widget_dialog.value.type === "GraphPie"){
      maxNumber.value = 10000
    }
  }

// INIT Dialog
    const init_widget_dialog = () => {
      //Initialisieren der Werte anhand der Store Werte
      widget_dialog.value = JSON.parse(JSON.stringify(widget.value));
      widget_dialog_template.value = {name: null, description:null}
      query_builder_value.value = deepClone(widget_dialog.value.widget_filters)
      //Nicht Cube Abhängige Options füllen
      computed_values()
      mutate_options()
      set_maxLimit()
      widget_dialog_visible.value = true
    }

// Save Dialog
    const widget_dialog_save = (widget_dialog, widget_id, type) => {
        if (widget_dialog.value.type === "WidgetTable") {
          // Neu hinzufügen der Columns, Reihenfolge bestimmt nacher die Reihenfolge in der Tabelle
          // Reset all Columns (Columns are calculated by backend)
          widget_dialog.value.columns = []
          // Create a Column for the Group
          widget_dialog.value.group.forEach((value) => {
            add_table_column({
              field: value,
              type: "group",
            })
          })
          // Create a Column for each KPI
          widget_dialog.value.groupedKpis.forEach((value) => {
            const code = value.value.split('/')[1].split('(')
            add_table_column({
              field: code[1].slice(0, -1),
              type: "aggregation",
              agg_type: code[0]
            })
          })
        } else {
          const code = widget_dialog.value.selected_single_kpi.code.split('/')
          widget_dialog.value.kpi = code[1]
          widget_dialog.value.calc = code[2]
          widget_dialog.value.attrtype = cubes_store.value.filter(cube => cube.code === widget_dialog.value.cube)[0].kpi.filter(kpi => kpi.code === widget_dialog.value.kpi)[0].type
          widget_dialog.value.unit = cubes_store.value.filter(cube => cube.code === widget_dialog.value.cube)[0].kpi.filter(kpi => kpi.code === widget_dialog.value.kpi)[0].unit[widget_dialog.value.calc]
        }
        widget_dialog.value.widget_filters = deepClone(query_builder_value.value)
        if (type === 'save'){
          remove_properties()
          store.commit('dashboard/editWidget', {'widget_dialog': widget_dialog, 'widget_id': widget_id})
          widget_dialog_visible.value = false;
          widget_dialog_submitted.value = false;
          widget_dialog_template_submitted.value = false;
        } else {
          let payload = deepClone(widget_dialog_template.value)
          payload.widget_json = deepClone(widget_dialog.value)
          store.dispatch('dashboard/saveData', {data: payload, model: 'widget_templates', successcallback: () => {
            widget_dialog_visible.value = false;
            widget_dialog_submitted.value = false;
            widget_dialog_template_submitted.value = false;
          }})
        }
      }

    // Add a column for Widget Table
    const add_table_column = (value) => {
      let header = ''
      let format = "text"
      if (value.type === 'group'){
        header = cubes_store.value.filter(cube => cube.code === widget_dialog.value.cube)[0].nokpi.filter(kpi => kpi.code === value.field)[0].kname
      } else {
        header = cubes_store.value.filter(cube => cube.code === widget_dialog.value.cube)[0].kpi.filter(kpi => kpi.code === value.field)[0].name
        header = `${t(`message.dashboard.${value.agg_type}`)}(${header})`
        format = "number"
      }

      let newcolumn = {
        field: value.field,
        header: header,
        type: value.type,
        format: format,
        headerStyle: "text-align: left;",
        headerClass: "text-align-left",
        bodyStyle: "text-align: left;",
      }
      if (value.agg_type) {
        newcolumn.agg_type = value.agg_type
        newcolumn.currency = cubes_store.value.filter(cube => cube.code === widget_dialog.value.cube)[0].kpi.filter(kpi => kpi.code === value.field)[0].unit[value.agg_type]
      }
      widget_dialog.value.columns.push(newcolumn)
    }

//Onchange
    const handle_onchange = (type, event) => {
      //Folgende Vars werden gefüllt
      //Titel widget_dialog.title (Char)
      //Widget Type options_widget_type -> widget_dialog.type
      //Cube Auswahl options_cubes_dialog -> widget_dialog.cube
      //Auswahl eines Single KPI options_single_kpi -> widget_dialog.selected_single_kpi (Char)
      //Auswahl eines Multi KPI options_multi_kpi -> widget_dialog.groupedKpis (Array)
      //Auswahl der XAxis options_xaxis -> widget_dialog.xaxis (Char)
      //Auswahl der Gruppierung options_group -> widget_dialog.group (Char)
      //Auswahl Table limit -> widget_dialog.limit (Int)
      //Auswahl Table Sort options_table_sort -> widget_dialog.selectedSort (Object)
      //Folgende Options stehen zur Verfügung
      if (type === 'widget_type') {
        widget_dialog.value.selected_single_kpi = undefined
        widget_dialog.value.groupedKpis = undefined
        widget_dialog.value.xaxis = undefined
        widget_dialog.value.group = undefined
        widget_dialog.value.limit = undefined
        widget_dialog.value.selectedSort = undefined
        widget_dialog.value.sort = undefined
        widget_dialog.value.kpi = undefined
        widget_dialog.value.columns = []
        options_group.value = undefined
        options_xaxis.value = undefined
        options_multi_kpi.value = undefined
        options_single_kpi.value = undefined
        options_table_sort.value = undefined
        set_maxLimit()

      } else if (type === 'cube') {

        //deepClone(value.default_widget_filters)
        widget_dialog.value.selected_single_kpi = undefined
        widget_dialog.value.groupedKpis = undefined
        widget_dialog.value.xaxis = undefined
        widget_dialog.value.group = undefined
        widget_dialog.value.columns = []
        widget_dialog.value.limit = undefined
        widget_dialog.value.selectedSort = undefined
        widget_dialog.value.sort = undefined
        let default_widget_filter = {"condition": "and", "rules": []}
        query_builder_value.value = {"condition": "and", "rules": []}
        for (const value of Object.entries(cubes_store.value)) {
          if (value[1].code ===  widget_dialog.value.cube) {
            if (value[1].default_widget_filters) {
              default_widget_filter = value[1].default_widget_filters
            }
          }
        }
        widget_dialog.value.widget_filters = deepClone(default_widget_filter)
        query_builder_value.value = deepClone(default_widget_filter)
        widget_dialog.value.columns = []
      } else if (type === 'multi_kpi') {
        try{
          /*
           *convert widget_dialog.value.groupedKpis values into array
            *check if groupedKpies array contains sort array
          * */
          let groupedKpies = widget_dialog.value.groupedKpis.map(function (kpi) {
            return kpi.value.split('/')[1]
          })
          const result = widget_dialog.value.sort.every(function(val) {
            return groupedKpies.indexOf(val) >= 0;
          });
          if (!result){
            widget_dialog.value.selectedSort = undefined
          }
        }
        catch (err) {
          widget_dialog.value.selectedSort = undefined
        }

      } else if (type === 'xaxis') {
        widget_dialog.value.group = undefined
      } else if(type === 'sort') {
        let code = event.value.code.split('/')
        widget_dialog.value.sort = [code[1]]
        widget_dialog.value.sort_ascending = code.pop() === 'ascending';
      } else if(type === 'widget_template') {
        let new_widget_dialog_value = JSON.parse(JSON.stringify(event.value));
        //TODO grouped KPI
        new_widget_dialog_value.x = widget_dialog.value.x
        new_widget_dialog_value.y = widget_dialog.value.y
        new_widget_dialog_value.w = widget_dialog.value.ws
        new_widget_dialog_value.h = widget_dialog.value.h
        widget_dialog.value = new_widget_dialog_value;
        query_builder_value.value = deepClone(widget_dialog.value.widget_filters)
        computed_values()
        mutate_options()
        set_maxLimit()
      }
      mutate_options()
    }

    const widget_type_changed = (event) => {
      handle_onchange('widget_type', event)
    }
    const widget_template_changed = (event) => {
      handle_onchange('widget_template', event)
    }
    const cube_changed = (event) => {
      handle_onchange('cube', event)
    }

    const kpis_changed = (event) => {
      handle_onchange('multi_kpi', event)
    }
    const xaxis_changed = (event) => {
      handle_onchange('xaxis', event)
    }
    const sort_changed = (event) => {
      handle_onchange('sort', event)
    }

    const changeRules = (queryBuilderRules) => {
      query_builder_value.value = queryBuilderRules
    };


//Context Menu
    const menu = ref();
    const menu_toggle = (event) => {
      menu.value.toggle(event);
    };
    const context_menu_hide = async () => {
      widget_dialog_submitted.value = false;
      widget_dialog_template_submitted.value = false;
    }
    const menu_items = ref([
      {
        label: t("message.dashboard.actions"),
        items: [
          {
            label: t("message.dashboard.settings"),
            icon: "mdi mdi-cog",
            command: () => {
              init_widget_dialog()
            }
          },
          {
            label: t("message.dashboard.delete"),
            disabled: false,
            icon: "mdi mdi-delete",
            command: () => {
              store.commit('dashboard/delete_widget', props.widget_id)
            }
          },

        ]
      },
    ]);

    return {
      menu_items,
      options_widget_type,
      options_widget_template,
      menu,
      menu_toggle,
      context_menu_hide,
      options_xaxis,
      options_group,
      widget_dialog_visible,
      options_cubes_dialog,
      widget_dialog,
      widget_dialog_save,
      widget_dialog_template,
      widget_dialog_template_selector,
      widget_dialog_validator,
      widget_dialog_template_validator,
      widget_dialog_submit,
      widget_dialog_submitted,
      widget_dialog_template_submitted,
      changeRules,
      mkeQueryBuilder,
      kpis_changed,
      options_multi_kpi,
      options_single_kpi,
      cube_changed,
      options_table_sort,
      sort_changed,
      widget_type_changed,
      widget_template_changed,
      edit_widget,
      xaxis_changed,
      maxNumber,
      t
    }
  }
}
</script>

